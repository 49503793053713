import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';

import { AppComponent } from './app.component';

import { LoginComponent } from './login/login/login.component';
import { LoginModule } from './login/login.module';
import { FormularioEditarAdministradorComponent } from './pages/administrador/formulario-editar-administrador/formulario-editar-administrador.component';
import { FormularioAdministradorComponent } from './pages/administrador/formulario-administrador/formulario-administrador.component';
import { ListarAdministradorComponent } from './pages/administrador/listar-administrador/listar-administrador.component';
import { ListarAprobadosComponent } from './pages/pasos/listar-aprobados/listar-aprobados.component';
import { ListarProcesoDispersionComponent } from './pages/pasos/listar-proceso-dispersion/listar-proceso-dispersion.component';
import { ListarDispersadosComponent } from './pages/pasos/listar-dispersados/listar-dispersados.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListarBitacoraComponent } from './pages/seguridad/listar-bitacora/listar-bitacora.component';
import { FormularioCancelarComponent } from './pages/pasos/formulario-cancelar/formulario-cancelar.component';
import { DetallesBitacoraComponent } from './pages/seguridad/detalles-bitacora/detalles-bitacora.component';
import { ListarCancelarComponent } from './pages/pasos/listar-cancelar/listar-cancelar.component';

/* COGNITO */
import { BrowserModule } from '@angular/platform-browser';
import Amplify, { Auth } from 'aws-amplify';
import { AdminLayoutComponent } from './pages/layout/admin-layout.component';

import { environment } from '../environments/environment';
import { FormularioEliminarComponent } from './pages/administrador/formulario-eliminar/formulario-eliminar.component';
import { ConfirmarCancelarComponent } from './pages/pasos/confirmar-cancelar/confirmar-cancelar.component';
import { DispersarMarketplaceComponent } from './pages/marketplace/dispersar-marketplace/dispersar-marketplace.component';
import { AboutComponent } from './pages/about/about.component';
import { ListarAprobadosTelecomComponent } from './pages/telecom/listar-aprobados-telecom/listar-aprobados-telecom.component';
import { ListarCancelarTelecomComponent } from './pages/telecom/listar-cancelar-telecom/listar-cancelar-telecom.component';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es-MX';
import { MensajedeprogresoComponent } from './shared/mensajedeprogreso/mensajedeprogreso.component';
import { LayoutSpeiTelecomComponent } from './pages/telecom/layout-spei-telecom/layout-spei-telecom.component';
import { ListarDispersionBbvaComponent } from './pages/bbva/listar-dispersion-bbva/listar-dispersion-bbva.component';
import { ListarPagadosBbvaComponent } from './pages/bbva/listar-pagados-bbva/listar-pagados-bbva.component';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: environment.awsmobile.aws_project_region,
    userPoolId: environment.awsmobile.aws_user_pools_id,
    userPoolWebClientId: environment.awsmobile.aws_user_pools_web_client_id,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: environment.awsmobile.oauth
  }
});
/*

 */
/* FIN COGNITO */

registerLocaleData(localeES);

@NgModule({
  imports: [
    NgxPaginationModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    AppRoutingModule,
    NgxSpinnerModule,
    LoginModule,
    BrowserModule, //cognito
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    FormularioEditarAdministradorComponent,
    FormularioAdministradorComponent,
    FormularioEliminarComponent,
    ListarAdministradorComponent,
    ListarAprobadosComponent,
    ListarProcesoDispersionComponent,
    ListarDispersadosComponent,
    ListarBitacoraComponent,
    FormularioCancelarComponent,
    DetallesBitacoraComponent,
    ListarCancelarComponent,
    ConfirmarCancelarComponent,
    DispersarMarketplaceComponent,
    ListarAprobadosTelecomComponent,
    ListarCancelarTelecomComponent,
    LayoutSpeiTelecomComponent,
    AboutComponent,
    MensajedeprogresoComponent,
    ListarDispersionBbvaComponent,
    ListarPagadosBbvaComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
