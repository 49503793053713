import { Injectable } from '@angular/core';
import { AwsService } from './aws.service';
import * as fileSaver from 'file-saver';
import { environment } from '../../environments/environment';
import { Adjuntos } from 'app/interfaces/adjuntos.interface';


const bucket_adjuntos = environment.awsAdjuntosBucket;
const adjuntos_base_key = "tesoreria/dispersion-marketplace";
@Injectable({
    providedIn: 'root'
  })
  export class UtilService {
    archivoCSVCorreo: Adjuntos;
    constructor(
      public awsService: AwsService,
    ){}
      //SHARE
      async uploadFiletoS3(filename: string, file: any, folder: string, noTxt: boolean = false, bucket: string = environment.awsFilesBucket) {
        let fullPath = folder + '/' + filename;
        fullPath += noTxt ? '' : '.txt';
        await this.awsService.uploadS3(fullPath, file, bucket);
        return this.awsService.getUrlFromBucket(fullPath, bucket);
      }

      //SHARE
      limpiarAcentos(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }


      //SHARE
      generarArchivo(nombre: string, contenido: string) {
        var blob = new Blob([contenido], { type: 'text/plain; charset=utf-8' });
        (window.URL || window.webkitURL).createObjectURL(blob);
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, nombre + '.txt');
      }

      //SHARE
      async generarCSV(nombre: string, contenido: string, seEnviaPorCorreo: boolean = false) {
        try {
          var blob = new Blob([contenido], { type: 'text/plain; charset=utf-8' });
          (window.URL || window.webkitURL).createObjectURL(blob);
          const url = window.URL.createObjectURL(blob);
          fileSaver.saveAs(blob, nombre + '.csv');
          if (seEnviaPorCorreo) {
            //subimos a s3 para el correo
            const urlFromBucket = await this.uploadFiletoS3(nombre + '.csv', blob, adjuntos_base_key, true, bucket_adjuntos);
            console.log("urlFromBucket", urlFromBucket);
            this.archivoCSVCorreo = {
              filename: nombre + '.csv',
              key: adjuntos_base_key + "/" + nombre + '.csv',
            }
          }
        } catch (err) {
          console.log("error al generar buffer de csv", err);
        }
    
      }
  }