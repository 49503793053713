import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
import { environment } from 'environments/environment';
import { AuthService } from '../../services/auth.service';

const base_url = environment.lambda_url;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  constructor(
    private router: Router,
    public authService: AuthService
  ) { }
 
  ngOnInit(): void { 
    console.log("base_url",base_url)
    //Redireccionamos al ADFS
    this.authService.getAutorizacionTesoreria();
    this.authService.iniciarSesionADFS();
  }
  
  irSiguiente(){
    this.router.navigateByUrl('/paso-1');
  }

  
}
