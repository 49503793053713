<h3 class="w-100 text-center">Actualiza tu password</h3> 
<div class="form-item">
    <p class="formLabel">Password anterior</p>
    <input type="password" name="passwordOld" id="passwordOld" class="form-style" [(ngModel)]="passwordold" />  
    <div class="text-danger w-100 text-center" *ngIf="errorPasswordViejo.length > 0">
        {{ errorPasswordViejo }}
    </div>
</div>
<div class="form-item">
    <p class="formLabel">Password nuevo</p>
    <input type="password" name="password" id="password" class="form-style" [(ngModel)]="password" />
    <div class="text-danger w-100 text-center" *ngIf="errorPassword1.length > 0">
        {{ errorPassword1 }}
    </div>
</div>
<div class="form-item">
    <p class="formLabel">Repite password nuevo</p>
    <input type="password" name="password2" id="password2" class="form-style" [(ngModel)]="password2" />  
    <div class="text-danger w-100 text-center" *ngIf="errorPassword.length > 0">
        {{ errorPassword }}
    </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="authService.getErrorLogin()">
    {{ authService.errorLoginMensaje() }}
</div>
<div class="alert alert-danger" role="alert" *ngIf="authService.getErrorLogin()">
    {{ authService.errorLoginMensaje() }}
</div>
<div class="form-item"> 
    <input type="submit" class="login pull-right" value="Cambiar password" (click)="actualizarPassword()">
    <div class="clear-fix"></div>
</div>
<div (click)="authService.irRegistro()">ir registro</div>
<div (click)="authService.irActualizaPassword()">ir actualiza password</div>