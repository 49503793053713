import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { S3Client, PutObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';

@Injectable({
    providedIn: 'root'
})
export class AwsService {

    client = new S3Client({
        region: environment.awsRegion,
        credentials: {
            accessKeyId: environment.awsAccessId,
            secretAccessKey: environment.awsSecret
        }
    });

    constructor() { }


    uploadS3(key: string, file: any, bucket: string) {
        const command = new PutObjectCommand({
            Bucket: bucket,
            Key: key,
            Body: file,
        })
        return this.client.send(command);
    }


    async getDataFile(url: string): Promise<string> {
        const filename = url.split('/').pop();
        let AWS = require('aws-sdk');
        AWS.config.update(
            {
                accessKeyId: environment.awsAccessId,
                secretAccessKey: environment.awsSecret
            }
        );

        let s3 = new AWS.S3();
        let request = await s3.getObject({ Bucket: environment.awsFilesBucket, Key: filename.split('.')[0] + '/' + filename }).promise();
        return request.Body.toString('ascii')
    }


    getUrlFromBucket(fileName: string, bucket: string) {
        return `https://${bucket}.s3.${environment.awsRegion}.amazonaws.com/${fileName}`
    }

}
