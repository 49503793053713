import { Injectable } from '@angular/core';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor() { }

  
  showNotification(from, align, type, message){
    //const type = ['','info','success','warning','danger'];
//pe-7s-check
//pe-7s-less
    var color = Math.floor((Math.random() * 4) + 1);
    $.notify({
        icon: (type=='success'? 'pe-7s-check' : 'pe-7s-less'),
        message: message
    },{
        type: type,
        timer: 2500,
        placement: {
            from: from,
            align: align
        }
    });
}
}
