import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DispersionService } from 'app/services/dispersion.service';
import { DispersionMarketplaceService } from 'app/services/dispersionMarketplace.service';
import { AuthService } from '../services/auth.service';
import { NotificacionService } from '../services/notificacion.service';
import { RespuestaApi } from '../interfaces/respuesta-api.interface';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  lista: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/paso-1', title: 'Paso 1: Generar Layout de Dispersión OP', icon: 'pe-7s-piggy', class: '', lista: 'HSBC' },
  { path: '/paso-2', title: 'Paso 2: Carga de Archivo de Respuesta Banco', icon: 'pe-7s-wallet', class: '', lista: 'HSBC' },
  { path: '/paso-3', title: 'Paso 3: Carga Transacciones Pagadas (OP Pagada)', icon: 'pe-7s-like2', class: '', lista: 'HSBC' },
  { path: '/cancelar', title: 'Paso 4: Generar Layout de Cancelación OP', icon: 'pe-7s-junk', class: '', lista: 'HSBC' },
  // { path: '/confirmar-cancelar' , title: 'Paso 5: Confirmar cancelaciones'                  ,  icon: 'pe-7s-trash'  , class: '' , lista: 'HSBC' },
  { path: '/administradores', title: 'Listar adminstradores', icon: 'pe-7s-users', class: '', lista: 'HSBC' },
  { path: '/bitacora', title: 'Listar movimientos bitácora', icon: 'pe-7s-safe', class: '', lista: 'HSBC' },

  { path: '/paso-1-marketplace', title: 'Paso 1: Generar layout Marketplace', icon: 'pe-7s-wallet', class: '', lista: 'MARKET' },

  { path: '/paso-1-telecom', title: 'Paso 1: Generar layout Telecomm', icon: 'pe-7s-wallet', class: '', lista: 'TELECOM' },
  { path: '/paso-2-telecom', title: 'Paso 2: Generar layout SPEI Telecomm', icon: 'pe-7s-wallet', class: '', lista: 'TELECOM' },
  { path: '/paso-3-telecom', title: 'Paso 3: Carga de Archivo de Respuesta', icon: 'pe-7s-like2', class: '', lista: 'TELECOM' },
  { path: '/paso-4-telecom', title: 'Paso 4: Giros Pagados', icon: 'pe-7s-like2', class: '', lista: 'TELECOM' }, // paso 4
  { path: '/paso-5-telecom', title: 'Paso 5: Generación layout cancelación Telecomm', icon: 'pe-7s-trash', class: '', lista: 'TELECOM' },
  { path: '/paso-6-telecom', title: 'Paso 6: Carga archivo respuesta cancelación', icon: 'pe-7s-like2', class: '', lista: 'TELECOM' },

  { path: '/paso-1-bbva', title: 'Paso 1: Generar Layout de Dispersion OP', icon: 'pe-7s-wallet', class: '', lista: 'BBVA' },
  { path: '/paso-2-bbva', title: 'Paso 2: Carga de archivo de respuesta (Dispersados)', icon: 'pe-7s-wallet', class: '', lista: 'BBVA' },
  { path: '/paso-3-bbva', title: 'Paso 3: Carga de transacciones pagadas (OP PAGADA)', icon: 'pe-7s-like2', class: '', lista: 'BBVA' },
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  setBanner = false;
  menuItems: any[];
  notificacionMarketplace: boolean = false;
  comboSelected: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    public dispersionService: DispersionService,
    public dispersionMarketplaceService: DispersionMarketplaceService,
    private notificacion: NotificacionService
  ) { }

  listaOpciones() {
    return ROUTES.filter((menuItem: any) => {
      return menuItem.lista == this.dispersionService.paginaLista;
    });
  }

  ngOnInit() {
    this.showBanner();
    this.comboSelected = this.dispersionService.paginaLista;
  }
  onChange(data: string) {
    this.dispersionService.paginaLista = data;
    this.comboSelected = data;
    let rutas = this.listaOpciones();
    if (rutas.length > 0) {
      this.router.navigate([rutas[0].path]);
    }
    this.showBanner();
  }

  showBanner() {
    this.dispersionService.getSolicitudesCancelacion().subscribe((respuesta: any) => {
      console.log(respuesta)
      if (respuesta.clientes.length > 0) {
        this.setBanner = true;
      } else {
        this.setBanner = false;
      }
    });

    this.dispersionMarketplaceService.getAprobadosMarketplace().subscribe((respuestaServidor: RespuestaApi) => {
      this.notificacionMarketplace = respuestaServidor.dispersiones.length > 0 || respuestaServidor.devoluciones.length > 0 ? true : false;
    }, (err: any) => {
      this.notificacion.showNotification('top', 'center', 'danger', 'Error al verificar si hay pendientes de Marketplace');
    });
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  logout() {
    //this.router.navigateByUrl('/dashboard');
    this.authService.cerrarSesion();
  }
}
