<div class="sidebar-wrapper">
    <div class="logo">
        <a class="simple-text">
            <div class="logo-img">
                <img src="/assets/img/logo-blanco.png" />
            </div>
            Tesorería
        </a>
    </div>
    <ul class="nav responsive-nav">
        <li *ngIf="isMobileMenu()">
            <a (click)="logout()">
                <p>Log out</p>
            </a>
        </li>
        <li class="separator hidden-lg hidden-md" *ngIf="isMobileMenu()"></li>
        <li *ngIf="notificacionMarketplace&&comboSelected!='MARKET'">
            <div class="notiMarket">
                <span style="padding: 15px;"><b> Revisar Marketplace <i style="float: initial !important; color:black"
                            class="pe-7s-bell"></i>
                    </b> </span>
            </div>

        </li>
        <li class="opcion-menu">
            <select class="form-control input-lg select-opciones" aria-label=".form-select-lg example" name="opcion"
                id="opcion" (change)="onChange($event.target.value)">
                <option value="HSBC" [selected]="this.dispersionService.paginaLista == 'HSBC'">HSBC</option>
                <option value="MARKET" [selected]="this.dispersionService.paginaLista == 'MARKET'">Market Place</option>
                <option value="TELECOM" [selected]="this.dispersionService.paginaLista == 'TELECOM'">Telecom</option>
                <option value="BBVA" [selected]="this.dispersionService.paginaLista == 'BBVA'">BBVA</option>
            </select>
        </li>
        <li routerLinkActive="active" *ngFor="let menuItem of listaOpciones()" class="{{ menuItem.class }}">
            <a [routerLink]="[menuItem.path]" (click)="showBanner()">
                <span *ngIf="menuItem.path == '/cancelar' && setBanner" class="badge"
                    style="background-color: #c2a500; position: absolute; top: 0px; left: 0px;">
                    <span class="glyphicon glyphicon-bell" aria-hidden="true"></span>
                </span>
                <i class="{{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>