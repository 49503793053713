import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { NotificacionService } from '../../../services/notificacion.service';
declare var $: any;

@Component({
  selector: 'app-actualiza-password',
  templateUrl: './actualiza-password.component.html',
  styleUrls: ['./actualiza-password.component.css']
})
export class ActualizaPasswordComponent implements OnInit {

  //Variables
  public email                = "";
  public password             = "";
  public password2            = "";
  public passwordold          = "";
  //Errores
  public errorPassword        = "";
  public errorPassword1       = "";
  public errorPasswordViejo   = "";
  public errorEmail           = "";

  public error = false; 

  constructor(
    public authService: AuthService,
    public notificacionService: NotificacionService
  ) { 
    this.email = authService.emailRegistro;
  }
 
  reiniciaError(){
    this.error = false; 
  }
  setError(){
    this.error = true; 
  }
  getError(){
    return this.error;
  }

  actualizarPassword(){
    this.reiniciaError();
    let error = false;
 
    if(this.password.length < 5){
      this.setError();
        this.errorPassword1 = "Este campo es obligatorio";
    }


    if(this.passwordold.length < 5){ 
      this.setError();
      this.errorPasswordViejo = "Este campo es obligatorio";
    }

    if(this.password != this.password2){ 
      this.setError();
      this.errorPassword = "Los passwords deben coincidir.";
    }
    if(!this.getError()){
      console.log("Dale");
      try{
        this.authService.actualizarPassword(this.passwordold ,this.password ).then( estatus => {
          console.log('Estatus: ', estatus);
          switch(estatus.code){
            case 'NotAuthorizedException':
              if(estatus.message == 'Incorrect username or password.'){
                this.notificacionService.showNotification('top','center','danger','Usuario o contraseña incorrectos.');
              }else{
                this.notificacionService.showNotification('top','center','danger','Error de autorización.');
              } 
              break;
            case 'LimitExceededException':
              this.notificacionService.showNotification('top','center','danger','Excediste el número de intentos, por favor espera.');
              break;
          }
        } ).catch(r => {
          console.log("Error ",r)
        } );
      }catch{ 
        console.log("Error en la autenticacion");
      } 
    }else{ 
      this.notificacionService.showNotification('top','center','danger','Error en los campos');
    }
  }

  ngOnInit(): void {
    var formInputs = $('input[type="email"],input[type="password"]');
    formInputs.focus(function() {
         $(this).parent().children('p.formLabel').addClass('formTop');
         $('div#formWrapper').addClass('darken-bg');
         $('div.logo').addClass('logo-active');
    });
    formInputs.focusout(function() {
      if ($.trim($(this).val()).length == 0){
      $(this).parent().children('p.formLabel').removeClass('formTop');
      }
      $('div#formWrapper').removeClass('darken-bg');
      $('div.logo').removeClass('logo-active');
    });
    $('p.formLabel').click(function(){
       $(this).parent().children('.form-style').focus();
    }); 
  }
}
