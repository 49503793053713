import { Injectable } from '@angular/core';
import { NotificacionService } from './notificacion.service';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'ts-xlsx';
import * as fileSaver from 'file-saver';
import { Dispersion } from 'app/interfaces/dispersion.class';
import { RegistroDispersion } from '../interfaces/registroDispersion.class';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { PdfService } from './pdf.service';
import { ExcelService } from './excel.service';
import { formatDate } from '@angular/common';
import { AwsService } from './aws.service';
import { TesoreriaLayout } from 'app/models/portal-layout';
import { UtilService } from './util.service';
import { Adjuntos } from '../interfaces/adjuntos.interface';
import { ExpedienteCliente } from "../interfaces/expedienteCliente";
import { Router } from '@angular/router';
import { ResumenConciliacion, RegistroArchivos } from 'app/models/archivos';
import { RespuestaApi } from 'app/interfaces/respuesta-api.interface';
const base_url = environment.lambda_url;
const bucket_adjuntos = environment.awsAdjuntosBucket;
const adjuntos_base_key = "tesoreria/dispersion-marketplace";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DispersionService {

  constructor(
    private notificacion: NotificacionService,
    private http: HttpClient,
    private authService: AuthService,
    private pdfService: PdfService,
    private excelService: ExcelService,
    public awsService: AwsService,
    private utilService: UtilService,
    private router: Router
  ) {
    this.paginaLista = this.router.url == "/paso-1-marketplace" ? "MARKET" : "HSBC";
  }
  /* para la lectura de excel */
  arrayBuffer: any;
  file: File;
  public excel: any = {};
  private nombreArchivo = 'Default';
  private referenciasProcesadas: Dispersion;
  public responsable = '';
  public paginaLista = 'HSBC';
  archivoExcelCorreo: Adjuntos;
  archivoCSVCorreo: Adjuntos;
  archivoPDFCorreo: Adjuntos;

  setResponsable(nombre: string, correo?: string) {
    this.responsable = nombre.toUpperCase() + "(" + correo?.toUpperCase() + ")";
  }

  async generarTexto(listaClientes: any[], version: number, tipo: string = "Dispersion") {
    try {

      let dispersion: Dispersion = new Dispersion();
      console.log("Version: ", version);
      dispersion.setVersion(version);

      for (let cliente of listaClientes) {
        let registro: RegistroDispersion = new RegistroDispersion();

        let montoLocal: number = + cliente.monto.toFixed(2);

        registro.nombreBeneficiario = this.utilService.limpiarAcentos(cliente.nombre.toUpperCase());
        registro.monto = montoLocal;
        registro.referencia = cliente.referencia;
        registro.autorizador = cliente.personalAdmin
        registro.tipoDeVenta = cliente.idTipoProducto == 2 ? 'Negocio' : 'Home Credit';
        registro.sucursalName = cliente.sucursal;
        registro.fechaAprobacion = cliente.fechaAprobacion == "" ? "" : formatDate(cliente.fechaAprobacion, 'short', 'es-MX', 'UTC');


        if (tipo == 'Cancelacion') {
          registro.setCancelacion();
          registro.fechaDispersion = cliente.fechaDispersion == "" ? "" : formatDate(cliente.fechaDispersion, 'short', 'es-MX', 'UTC');
          registro.fechaCancelacion = cliente.fechaCancelacion == "" ? "" : formatDate(cliente.fechaCancelacion, 'short', 'es-MX', 'UTC');
          registro.cancelador = cliente.cancelador;
        }
        dispersion.addRegistro(registro);
      }
      this.nombreArchivo = dispersion.getNombreArchivo();
      this.referenciasProcesadas = dispersion;
      this.utilService.generarArchivo(this.nombreArchivo, dispersion.toFile());

      if (tipo === 'Dispersion') {
        const res = await this.utilService.uploadFiletoS3(this.nombreArchivo, dispersion.toFile(), this.nombreArchivo);
        const dispersionFile: TesoreriaLayout = {
          nombreArchivo: this.nombreArchivo + '.txt',
          filePath: res
        };
        this.saveRegisterLayout(dispersionFile);
      }

      this.notificacion.showNotification('top', 'center', 'success', 'Se actualizaron los registros correctamente');
      return true;
    } catch (error) {
      console.log("generarTexto");
      console.log(error);
      this.notificacion.showNotification('top', 'center', 'danger', 'Error al generar los registros');
    }
    return false;
  }

  getSocio(idSocio) {
    return this.http.get(`${base_url}/api/dispersion/socios/${idSocio}`, this.authService.headers);
  }

  saveRegisterLayout(dispersion: TesoreriaLayout) {
    this.setLayoutDispersion(dispersion).subscribe(() => { });
  }

  async getDataFile(path: string) {

    this.awsService.getDataFile(path);

    return this.awsService.getDataFile(path);
  }

  //SHARE?
  async leerExcel(ev) {
    let excel = {};
    await new Promise((lecturaOk, errorLeer) => {
      try {
        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        const file = ev.target.files[0];
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          excel = (jsonData);
          lecturaOk(excel);
        }
        reader.readAsBinaryString(file);
      } catch (error) {
        errorLeer({});
      }
    })
    return excel;
  }

  referenciasExcel(excel) {
    var nombrePrimerHojaExcel = Object.keys(excel)[0]; //El excel tiene como primer objeto el nombre de la hoja.
    let celdas = excel[nombrePrimerHojaExcel];
    let referencias: any = [];
    for (let celda of celdas) {
      let temp: {
        descripcion: string,
        cobro: string
      } = {
        descripcion: '',
        cobro: ''
      };

      if (celda['Hora de cargo o abono']) {
        temp.cobro = celda['Hora de cargo o abono'] + ':00.000Z';
      }
      if (celda['Descripción']) {
        temp.descripcion = celda['Descripción'];
      }
      if (celda['Fecha del apunte']) {
        let cobro: any = new Date(celda['Fecha del apunte']).toLocaleDateString('es-mx').split('/').reverse()
        for (let j = 1; j < cobro.length; j++) {
          if (cobro[j].length == 1) cobro[j] = '0' + cobro[j];
        }
        cobro = cobro.join('-');
        cobro += 'T';
        temp.cobro = cobro + temp.cobro;
      }
      referencias.push(temp);
    }
    return referencias;
  }

  generarPDF(tipo: string = "dispersion") {
    this.pdfService.generatePDF(this.referenciasProcesadas.getNombreArchivo(), this.referenciasProcesadas, this.responsable, tipo);
  }

  generarXLSXConciliacion(nombreArchivo: string, resumen: ResumenConciliacion) {
    this.excelService.crearExcelConciliacion(nombreArchivo, resumen);
  }

  generarPDFConciliacion(nombreArchivo: string, resumen: ResumenConciliacion) {
    this.pdfService.generatePDFConciliacion(nombreArchivo, resumen);
  }

  generarXLSX(tipo: string = "dispersion") {
    let nombreArchivo = this.referenciasProcesadas.getNombreArchivo();
    let dispersiones = this.referenciasProcesadas.toPDF();
    let renglones = [];

    renglones.push(['FECHA', 'PERSONA RESPONSABLE', 'MONTO', 'OPERACIONES']);
    renglones.push([dispersiones.cabecera.fecha, this.responsable, '$' + (dispersiones.cabecera.montoTotal.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","), dispersiones.cabecera.totalOperaciones]);

    if (tipo == "cancelacion") {
      renglones.push(['NO.', 'TIPO DE VENTA', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE CANCELÓ', 'SUCURSAL', 'FECHA/HORA CANCELACIÓN', 'FECHA/HORA DISPERSIÓN']);
      for (let i = 0; i < dispersiones.cuerpo.length; i++) {
        renglones.push({
          'NO.': (i + 1) + '',
          'TIPO DE VENTA': '' + dispersiones.cuerpo[i].tipoDeVenta,
          'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
          'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
          'TIPO': '' + dispersiones.cuerpo[i].tipoMovimiento,
          'PERSONAL ADMIN QUE CANCELÓ': '' + dispersiones.cuerpo[i].cancelador,
          'SUCURSAL': '' + dispersiones.cuerpo[i].sucursalName,
          'FECHA/HORA CANCELACIÓN': '' + dispersiones.cuerpo[i].fechaCancelacion,
          'FECHA/HORA DISPERSIÓN': '' + dispersiones.cuerpo[i].fechaDispersion,
        });
      }

    } else if (tipo == "dispersion") {

      renglones.push(['NO.', 'TIPO DE VENTA', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE APROBÓ', 'SUCURSAL', 'FECHA Y HORA']);
      for (let i = 0; i < dispersiones.cuerpo.length; i++) {
        renglones.push({
          'NO.': (i + 1) + '',
          'TIPO DE VENTA': '' + dispersiones.cuerpo[i].tipoDeVenta,
          'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
          'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
          'TIPO': '' + dispersiones.cuerpo[i].tipoMovimiento,
          'PERSONAL ADMIN QUE APROBÓ': '' + dispersiones.cuerpo[i].autorizador,
          'SUCURSAL': '' + dispersiones.cuerpo[i].sucursalName,
          'FECHA Y HORA': '' + dispersiones.cuerpo[i].fechaAprobacion
        });
      }

    }

    this.excelService.crearExcel(nombreArchivo, renglones);
  }

  setLayoutDispersion(tesoreriaLayout: TesoreriaLayout) {
    return this.http.post(`${base_url}/api/dispersion/guarda-dispersion-layout`, { tesoreriaLayout: tesoreriaLayout }, this.authService.headers)
  }

  numeroConComas(textoNumerico: string) {
    return textoNumerico.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getAprobados() {
    return this.http.get<RespuestaApi>(`${base_url}/api/dispersion/aprobados`, this.authService.headers);
  }

  getAprobadosMarketplace() {
    return this.http.get(`${base_url}/api/marketplace/aprobados-marketplace`, this.authService.headers);
  }

  getDispersados() {
    return this.http.get(`${base_url}/api/dispersion/dispersados`, this.authService.headers);
  }

  getBitacora() {
    return this.http.get(`${base_url}/api/dispersion/bitacora`, this.authService.headers);
  }

  getSolicitudesCancelacion() {
    return this.http.get(`${base_url}/api/dispersion/proceso-cancelacion`, this.authService.headers);
  }

  getDetalleBitacora(idBitacora) {
    return this.http.get(`${base_url}/api/dispersion/bitacora/${idBitacora}`, this.authService.headers);
  }

  setEnProcesoDeDispersion(registros: any[]) {
    return this.http.put(`${base_url}/api/dispersion/proceso-dispersion`, { registros: registros }, this.authService.headers);
  }

  setCompraFinalizada(dispersiones: ExpedienteCliente[], devoluciones: ExpedienteCliente[]) {
    console.log(dispersiones, devoluciones);
    return this.http.put(`${base_url}/api/marketplace/compra-finalizada`, { dispersiones, devoluciones }, this.authService.headers);
  }

  setDispersados(referencias: { descripcion: string }[]) {
    return this.http.put(`${base_url}/api/dispersion/dispersado`, { referencias: referencias }, this.authService.headers);
  }

  setConfirmarCancelado(referencias: { descripcion: string }[]) {
    return this.http.put(`${base_url}/api/dispersion/cancelado`, { referencias: referencias }, this.authService.headers);
  }

  setCobrados(referencias: string[]) {
    return this.http.put(`${base_url}/api/dispersion/cobrado`, { referencias: referencias }, this.authService.headers);
  }


  setCancelado(referencia: string, motivo: string) {
    return this.http.put(`${base_url}/api/dispersion/cancelado`, { referencia: referencia, motivo: motivo }, this.authService.headers);
  }

  sendEmail(tipo: string, adjuntos: Adjuntos[] = []) {
    return this.http.post(`${base_url}/api/dispersion/enviarCorreo`, { tipo, adjuntos }, this.authService.headers);
  }

  subirLayoutRespuestaDB(objetoDB) {
    return this.http.post(`${base_url}/api/dispersion/subir_layout_respuesta_db`, objetoDB, this.authService.headers).toPromise();
  }

  guardarDetallesLayout(datos) {
    return this.http.post(`${base_url}/api/dispersion/guardar_layout_detalles`, datos, this.authService.headers).toPromise();
  }

  setEnProcesoDeCancelacion(registros: any[]) {
    return this.http.put(`${base_url}/api/dispersion/proceso-cancelacion`, { registros: registros }, this.authService.headers);
  }
  async getLastLayoutFileS3(url) {
    const contenidoArchivo = await this.awsService.getDataFile(url);
    return contenidoArchivo;
  }

  getLastLayoutFileDB() {
    return this.http.get(`${base_url}/api/dispersion/last_layout_dispersion`, this.authService.headers).toPromise();
  }

  getReferenciasParaConcilacion(referencias: RegistroArchivos[]) {
    return this.http.put<RegistroArchivos[]>(`${base_url}/api/dispersion/listarReferenciasParaConciliacion`, { referencias }, this.authService.headers).toPromise();
  }
}
