<div class="form-item">
    <p class="formLabel">Email</p>
    <input type="email" name="email" id="email" class="form-style" autocomplete="off" [(ngModel)]="email"/>
</div>
<div class="form-item">
    <p class="formLabel">Password</p>
    <input type="password" name="password" id="password" class="form-style" [(ngModel)]="password" />  
</div>
<div class="alert alert-danger" role="alert" *ngIf="authService.getErrorLogin()">
    {{ authService.errorLoginMensaje() }}
</div>
<div class="form-item"> 
    <input type="submit" class="login pull-right" value="Iniciar sesión" (click)="iniciarSesion()">
    <div class="clear-fix"></div>
</div> 
<div class="form-item"> 
    <input type="submit" class="login pull-right" value="ADFS" (click)="iniciarSesionADFS()">
    <div class="clear-fix"></div>
</div> 
<a (click)="authService.irRegistro()" >Registro</a>