// export const environment = {
//   production: false,
//   lambda_url: "https://teso-api-dev.providigital.com",
//   logger_url: 'https://811erttxp2.execute-api.us-east-1.amazonaws.com/qa/originacionactivitylogger',
//   logger_old_url: 'https://811erttxp2.execute-api.us-east-1.amazonaws.com/qa/useractivitylogger',
//   jwt: 'Dk6SO5HRW5VWWe4JFV3EWd73ErYbGas5Z7fKVzDo50LYkC0aan',
//   awsmobile: { //PROVIDENT
//     aws_project_region: "us-east-1",
//     aws_cognito_region: "us-east-1",
//     aws_user_pools_id: "us-east-1_i8WFXfLE6",  //Provident AD
//     aws_user_pools_web_client_id: "1qipp85pri0pdudb8cil8hv707", //Provident AD   
//     oauth: {
//       domain: "providentcogn.auth.us-east-1.amazoncognito.com",
//       scope: ['email', 'openid'],
//       redirectSignIn: 'https://tesoreria-dev.providigital.com/paso-1',
//       redirectSignOut: 'https://tesoreria-dev.providigital.com/',
//       responseType: 'token'
//     }
//   },

//   awsAccessId: "AKIA5XGNV4I7YXXA2PU4",
//   awsSecret: "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",
//   awsRegion: "us-east-1",
//   awsFilesBucket: "teso-dev-layouts",
//   awsAdjuntosBucket: "adjuntos-correos-dev"
// };

export const environment = {
  production: false,
  lambda_url: "https://hkncqnzq6l.execute-api.us-east-1.amazonaws.com/dev/tesoreria",
  logger_url: 'https://811erttxp2.execute-api.us-east-1.amazonaws.com/qa/originacionactivitylogger',
  logger_old_url: 'https://811erttxp2.execute-api.us-east-1.amazonaws.com/qa/useractivitylogger',
  awsmobile: { //PROVIDENT
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_i8WFXfLE6", //Provident AD
    aws_user_pools_web_client_id: "7b88m8d3l6vti8ofg54hpb6q5h", //Provident AD
    oauth: {
      domain: "providentcogn.auth.us-east-1.amazoncognito.com",
      scope: ['email', 'openid'],
      redirectSignIn: 'https://tesoreria-dev-new.providigital.com/paso-1',
      redirectSignOut: 'https://tesoreria-dev-new.providigital.com/',
      responseType: 'token'
    }
  },

  awsAccessId: "AKIA5XGNV4I7YXXA2PU4",
  awsSecret: "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",
  awsRegion: "us-east-1",
  awsFilesBucket: "teso-dev-layouts",
  awsAdjuntosBucket: "adjuntos-correos-dev"
};