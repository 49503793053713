import { RegistroDispersion } from "./registroDispersion.class";

export class Marketplace {

    public version = '1';
    public formatoArchivo: string = "MXPDLF";
    public cuentaProvidentMarketplace: string = "4067839068";
    public beneficiarioMarketplace: string = "4066649609";
    public nivelAutorizacion: string = "F";
    public montos: RegistroDispersion[] = [];
    public get totalOperaciones() {
        //El total de operaciones son las dispersiones
        return this.montos.length;
    };
    public get montoTotal() {
        //La suma de las dispersiones
        let montoTotal: number = 0;
        for(let i = 0; i < this.montos.length; i++){
            let cantidad: number =  + this.montos[i].monto.toFixed(2);
            if(this.montos[i].esDevolucion){
                montoTotal -= cantidad;
            }else{
                montoTotal += cantidad; 
            }
        }
        let respuesta: number = +montoTotal.toFixed(2);
        return respuesta;
    };

    constructor() {
    }

    getNombreArchivo() {
        let fecha = new Date();
        return "PT_" + fecha.getFullYear() + "" + this.cerosIzquierda('' + (fecha.getMonth() + 1), 2) + "" + this.cerosIzquierda('' + fecha.getDate(), 2) + "_" + this.cerosIzquierda(this.getVersion(), 4);
    }
    setVersion(version: any) {
        this.version = version + '';
    }
    getFecha() {
        let fecha = new Date();
        return this.cerosIzquierda('' + fecha.getDate(), 2) + "/" + this.cerosIzquierda('' + (fecha.getMonth() + 1), 2) + "/" + fecha.getFullYear()
    }

    getVersion() {
        return this.version;
    }

    addRegistro(registro: RegistroDispersion) {
        this.montos.push(registro);
    }

    toFile() {
        //Se presenta en formato de la cabecera.

        let montoTitulo: number = +this.montoTotal.toFixed(2);
        let respuesta = "" + this.formatoArchivo;
        respuesta += "" + this.getNombreArchivo();
        respuesta += "" + this.nivelAutorizacion;
        respuesta += "" + this.cerosIzquierda('' + ((montoTitulo * 100).toFixed(0)), 15); //MONTO EXPRESADO EN CENTAVOS
        respuesta += "" + this.cerosIzquierda('' + this.totalOperaciones, 9) + "";

        //Se agrega linea a linea
        for (let i = 0; i < this.montos.length; i++) {
            respuesta += "\n" + this.montos[i].toFile();
        }
        console.log("Archivo generado");
        console.log(respuesta);
        return respuesta;
    }

    selectBankAccount( idTransferencia: number ): string {
        return idTransferencia == 1 ? 'NumeroCuenta' : 'Clabe';
    }

    toMarketplace( socioData ) {
        //primero validamos si el monto total es igual a 0
        if (+this.montoTotal === 0) {
            return {
                esCero: true,
                respuesta: ''
            };
        }
        //Se presenta en formato de la cabecera.

        let montoTitulo: number = +this.montoTotal.toFixed(2);
        let respuesta = "";

        respuesta = "1";                                            //Transacciones
        respuesta += "," + this.cuentaProvidentMarketplace;         //Cuenta ordenante
        respuesta += "," + this.beneficiarioMarketplace; //Cuenta beneficiaria
        respuesta += "," + this.montoTotal.toFixed(2)               //Sumatoria registros
        respuesta = "1";                                            //Transacciones
        respuesta += "," + this.cuentaProvidentMarketplace;         //Cuenta ordenante
        respuesta += "," + socioData[ this.selectBankAccount( socioData.FK_IdTransferencia ) ]; //Cuenta beneficiaria
        respuesta += "," + this.montoTotal.toFixed(2)               //Sumatoria registros
        respuesta += ",1"                                           //Moneda;
        respuesta += ",PROVIDENT"                                   //Referencia;
        respuesta += "," + socioData.Nombre;                        //Nombre del beneficiario
        respuesta += ",0"                                           //Comprobante fiscal
        respuesta += ","                                            //RFC del beneficiario
        respuesta += ","                                            //IVA
        respuesta += ","                                            //Correo electronico
        respuesta += "\n"                                           //Salto de línea

        //Segunda linea
        respuesta += "1"                                            //Numero de bloque
        respuesta += ",1"                                           //Transacciones
        respuesta += "," + this.espaciosDerecha('PAGO PROVIDENT', 40);//concepto
        respuesta += ","                                            //Filler 1
        respuesta += ","                                            //Filler 2
        respuesta += ","                                            //Filler 3
        respuesta += ","                                            //Filler 4
        respuesta += ","                                            //Filler 5
        respuesta += ","                                            //Filler 6
        respuesta += ","                                            //Filler 7
        respuesta += ","                                            //Filler 8



        console.log("Archivo generado", respuesta);
        return {
            esCero: false,
            respuesta
        }
    };
    toPDF() {
        let arregloMontos: {
            nombre: string,
            monto: number,
            referencia: string,
            tipoMovimiento: string,
            autorizador: string,
            esDevolucion: boolean
        }[] = [];

        for (let i = 0; i < this.montos.length; i++) {
            arregloMontos.push(this.montos[i].toPDF())
        }

        return {

            cabecera: {
                nombreArchivo: this.getNombreArchivo(),
                montoTotal: this.montoTotal,
                fecha: this.getFecha(),
                totalOperaciones: this.totalOperaciones
            },
            cuerpo: arregloMontos
        };
    }

    cerosIzquierda(cadena: string, ceros: number = 30) {
        for (let i = cadena.length; i < ceros; i++) {
            cadena = '0' + cadena;
        }
        return cadena;
    }

    espaciosDerecha(cadena: string, espacios: number = 40) {
        for (let i = cadena.length; i < espacios; i++) {
            cadena = cadena + ' ';
        }
        return cadena;
    }

}