import { Injectable } from '@angular/core';
import { NotificacionService } from './notificacion.service';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'ts-xlsx';
import * as fileSaver from 'file-saver';

import { RegistroDispersion } from '../interfaces/registroDispersion.class';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { PdfService } from './pdf.service';
import { ExcelService } from './excel.service';
import { Marketplace } from 'app/interfaces/marketplace.class';
import { formatDate } from '@angular/common';
import { AwsService } from './aws.service';
import { UtilService } from './util.service';

import { Adjuntos } from '../interfaces/adjuntos.interface';
import { ExpedienteCliente } from "../interfaces/expedienteCliente";
import { Router } from '@angular/router';
const base_url = environment.lambda_url;
const bucket_adjuntos = environment.awsAdjuntosBucket;
const adjuntos_base_key = "tesoreria/dispersion-marketplace";
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DispersionMarketplaceService {

  constructor(
    private notificacion: NotificacionService,
    private http: HttpClient,
    private authService: AuthService,
    private pdfService: PdfService,
    private excelService: ExcelService,
    public awsService: AwsService,
    private router: Router,
    private utilService: UtilService,
  ) {
    this.paginaLista = this.router.url == "/paso-1-marketplace" ? "MARKET" : "HSBC";
  }
  /* para la lectura de excel */
  arrayBuffer: any;
  file: File;
  public excel: any = {};
  private nombreArchivo = 'Default';

  private referenciasProcesadasMarketplace: Marketplace;
  public responsable = '';
  public paginaLista = 'MARKET';
  public notificacionMarketplace: boolean = false;
  archivoExcelCorreo: Adjuntos;
  archivoCSVCorreo: Adjuntos;
  archivoPDFCorreo: Adjuntos;


  generarTextoMarketplace(listaClientes: any[], version: number, tipo: string = "Dispersion") {
    try {

      let dispersion: Marketplace = new Marketplace();
      console.log("Version: ", version);
      dispersion.setVersion(version);

      for (let cliente of listaClientes) {
        let registro: RegistroDispersion = new RegistroDispersion();

        let montoLocal: number = + cliente.monto.toFixed(2);

        registro.nombreBeneficiario = this.utilService.limpiarAcentos(cliente.nombre.toUpperCase());
        registro.monto = montoLocal;
        registro.referencia = cliente.referencia;
        registro.autorizador = cliente.personalAdmin
        registro.tipoDeVenta = cliente.idTipoProducto == 2 ? 'Negocio' : 'Home Credit';
        registro.sucursalName = cliente.sucursal;
        registro.fechaAprobacion = cliente.fechaAprobacion == "" ? "" : formatDate(cliente.fechaAprobacion, 'short', 'es-MX', 'UTC');


        if (tipo == 'Cancelacion') {
          registro.setCancelacion();
          registro.fechaDispersion = cliente.fechaDispersion == "" ? "" : formatDate(cliente.fechaDispersion, 'short', 'es-MX', 'UTC');
          registro.fechaCancelacion = cliente.fechaCancelacion == "" ? "" : formatDate(cliente.fechaCancelacion, 'short', 'es-MX', 'UTC');
          registro.cancelador = cliente.cancelador;
        }
        dispersion.addRegistro(registro);
      }
      this.nombreArchivo = dispersion.getNombreArchivo();
      this.referenciasProcesadasMarketplace = dispersion;
      this.utilService.generarArchivo(this.nombreArchivo, dispersion.toFile());
      this.notificacion.showNotification('top', 'center', 'success', 'Se actualizaron los registros correctamente');
      return true;
    } catch (error) {
      console.log("generarTexto");
      console.log(error);
      this.notificacion.showNotification('top', 'center', 'danger', 'Error al generar los registros');
    }
  }

  getSocio( idSocio ) {
    return this.http.get(`${base_url}/api/dispersion/socios/${ idSocio }`, this.authService.headers);
  }

  async generarCSV_Marketplace(
    dispersiones: ExpedienteCliente[],
    devoluciones: ExpedienteCliente[],
    version: number,
    socio: any,
    tipo: string = "Dispersion"
  ) {
    try {

      let dispersionMarketplace: Marketplace = new Marketplace();
      console.log("Version: ", version);
      dispersionMarketplace.setVersion(version);

      //dispersiones
      for (let dispersion of dispersiones) {
        let registro: RegistroDispersion = new RegistroDispersion();

        let montoLocal: number = + dispersion.monto;

        registro.nombreBeneficiario = this.utilService.limpiarAcentos(dispersion.nombre.toUpperCase());
        registro.monto = montoLocal;
        registro.referencia = dispersion.referencia;
        registro.autorizador = dispersion.personalAdmin;
        registro.esDevolucion = false;
        if (tipo == 'Cancelacion') {
          registro.setCancelacion();
        }
        dispersionMarketplace.addRegistro(registro);
      }
      //ahora las cancelaciones
      for (let devolucion of devoluciones) {
        let registro: RegistroDispersion = new RegistroDispersion();

        let montoLocalDesc: number = + devolucion.totalDevolucion;

        registro.nombreBeneficiario = this.utilService.limpiarAcentos(devolucion.nombre.toUpperCase());
        registro.monto = montoLocalDesc;
        registro.referencia = devolucion.referencia;
        registro.autorizador = devolucion.cancelador;
        registro.esDevolucion = true;
        if (tipo == 'Cancelacion') {
          registro.setCancelacion();
        }
        dispersionMarketplace.addRegistro(registro);
      }

      this.nombreArchivo = dispersionMarketplace.getNombreArchivo();
      this.referenciasProcesadasMarketplace = dispersionMarketplace;
      const contenidoArchivo = dispersionMarketplace.toMarketplace(socio);
      if (contenidoArchivo.esCero) {
        await this.generarXLSX_Marketplace();
        await this.generarPDF_Marketplace();
        return 'esCero';
      }
      await this.utilService.generarCSV(this.nombreArchivo, contenidoArchivo.respuesta, true);
      await this.generarXLSX_Marketplace();
      await this.generarPDF_Marketplace();
      this.notificacion.showNotification('top', 'center', 'success', 'Se actualizaron los registros correctamente');
      return 'exito';
    } catch (error) {
      console.log("catch en generarCSV_Marketplace", error);
      this.notificacion.showNotification('top', 'center', 'danger', 'Error al generar los registros');
      return JSON.stringify(error);
    }
  }

  async generarPDF_Marketplace() {
    const { filename, contenido } = await this.pdfService.generatePDF_Marketplace(this.referenciasProcesadasMarketplace.getNombreArchivo(), this.referenciasProcesadasMarketplace, this.responsable);
    const urlFromBucket = await this.utilService.uploadFiletoS3(filename, contenido, adjuntos_base_key, true, bucket_adjuntos);
    console.log("urlFromBucket", urlFromBucket);
    this.archivoPDFCorreo = {
      filename,
      key: adjuntos_base_key + "/" + filename
    }
  }

  async generarXLSX_Marketplace() {
    let nombreArchivo = this.referenciasProcesadasMarketplace.getNombreArchivo();

    let dispersiones = this.referenciasProcesadasMarketplace.toPDF();
    let renglones = [];

    renglones.push(['FECHA', 'PERSONA RESPONSABLE', 'MONTO', 'OPERACIONES']);
    renglones.push([dispersiones.cabecera.fecha, this.responsable, '$' + (dispersiones.cabecera.montoTotal.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","), dispersiones.cabecera.totalOperaciones]);



    renglones.push(['NO.', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE APROBÓ']);
    for (let i = 0; i < dispersiones.cuerpo.length; i++) {
      renglones.push({
        'NO.': (i + 1) + '',
        'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
        'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
        'TIPO': '' + dispersiones.cuerpo[i].tipoMovimiento,
        'PERSONAL ADMIN QUE APROBÓ': '' + dispersiones.cuerpo[i].autorizador
      });
    }

    const { filename, contenido } = await this.excelService.crearExcel_Marketplace(nombreArchivo, renglones);
    const urlFromBucket = await this.utilService.uploadFiletoS3(filename, contenido, adjuntos_base_key, true, bucket_adjuntos);
    console.log("urlFromBucket", urlFromBucket);
    this.archivoExcelCorreo = {
      filename,
      key: adjuntos_base_key + "/" + filename
    }


    this.excelService.crearExcel(nombreArchivo, renglones);
  }

  getAprobadosMarketplace() {
    return this.http.get(`${base_url}/api/marketplace/aprobados-marketplace`, this.authService.headers);
  }

  setCompraFinalizada(dispersiones: ExpedienteCliente[], devoluciones: ExpedienteCliente[]) {
    console.log(dispersiones, devoluciones);
    return this.http.put(`${base_url}/api/marketplace/compra-finalizada`, { dispersiones, devoluciones }, this.authService.headers);
  }
}