<form>
    <div style="margin-bottom: 15px; ">
        <h3 class="text-center">Registrate</h3>
    </div>
    <div class="form-item">
        <p class="formLabel">Nombre</p>
        <input type="text" name="nombre" id="nombre" class="form-style" autocomplete="off" [(ngModel)]="nombre"/>
    </div> 
    <div class="form-item">
        <p class="formLabel">Apellidos</p>
        <input type="text" name="apellidos" id="apellidos" class="form-style" autocomplete="off" [(ngModel)]="apellidos"/>
    </div>  
    <div class="form-item">
        <p class="formLabel">Email</p>
        <input type="email" name="email" id="email" class="form-style" autocomplete="off" [(ngModel)]="email"/>
    </div>
    <div class="form-item">
        <p class="formLabel">Password</p>
        <input type="password" name="password" id="password" class="form-style" autocomplete="off" [(ngModel)]="password"/>
    </div> 
    <div class="alert alert-danger" role="alert" *ngIf="authService.getErrorLogin()">
        {{ authService.errorLoginMensaje() }}
    </div>
    <div class="form-item"> 
        <input type="submit" class="login pull-right" value="Registrar" (click)="registrarse()">
        <div class="clear-fix"></div>
    </div> 

</form>
<p class="text-dark mb-0">
    ¿Ya estás registrado? 
    <a (click)="authService.irLogin()" class="text-primary ml-1">
        Iniciar sesión
    </a>
</p>