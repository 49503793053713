<div class="back">
    <div id="formWrapper">

        <div id="form">
        <div class="logo">
            <img src="../../../assets/img/logo.png" alt="" title="" />
            <h1 class="text-center head">Panel de Tesorería</h1>
        </div>
        
            <div class="form-item" *ngIf="authService.getPaso() == 'adfs'"> 
                <input type="submit" class="login pull-right" style="width:100%" value="Iniciar sesión - Active Directory" (click)="this.authService.iniciarSesionADFS()">
                <div class="clear-fix"></div>
            </div> 
            <app-formulario-login       *ngIf="authService.getPaso() == 'inicio'"></app-formulario-login>
            <app-formulario-registro    *ngIf="authService.getPaso() == 'registro'"></app-formulario-registro>
            <app-actualiza-password     *ngIf="authService.getPaso() == 'actualiza-password'"></app-actualiza-password>
        </div>
    </div>
</div>
<ngx-spinner 
                bdColor = "rgba(89,89,89,0.4)"
                size = "default" 
                color = "#fff" 
                type = "timer" 
                [fullScreen] = "false">
                    <p style="color: white" > Procesando </p>
                </ngx-spinner>