export class RegistroDispersion {
    public cuentaDebito: string = "4065204968";
    public moneda: string = "1";
    public estatus: string = "01";  //01=Nueva instrucción de pago 
    //03=Instrucción cancelada 
    //04=Instrucción suspendida 
    //05=Instrucción reactivada
    public nombreBeneficiario: string = "";
    public monto: number = 0;
    public referencia: string = "";
    public fechaValor: string = "";
    public fechaAplicacion: string = "";
    public fechaExpiracion: string = "";
    public usuario: string = "CONNECT ";
    public sucursal: string = "08000";
    public serial: string = "00000000000000";
    //DIA SIGUIENTE     = 00000000
    //MISMO DIA         = 02000000
    public folio: string = "02000000"; 
    public numeroServicioDAP: string = "1763   "; 
    public cuentaBeneficiaria: string = "0000000000"; 
    public codigoPago: string = "0";
    public autorizador: string = "Default";
    //DATOS TELECOM
    public nombreC: string = "";
    public apellidoP: string = "";
    public apellidoM: string = "";
    public direccion: string = "";
    public colonia: string = "";
    public calle: string = "";
    public oficina: string = "14007"; //DEFAULT 2041
    public referenciaTelecom: string = "";
    
    public tipoDeVenta: string = "";
    public sucursalName: string = "";
    public fechaAprobacion: string = "";
    public fechaDispersion: string = "";
    public fechaCancelacion: string = "";
    public cancelador: string = "";
    public esDevolucion: boolean = false;

    constructor() {
        let fecha = new Date();
        let manana = new Date();
        let exp = new Date();

        manana.setDate(manana.getDate() + 0); //+1
        exp.setDate(exp.getDate() + 21);

        this.fechaValor = fecha.getFullYear() + "" + this.cerosIzquierda('' + (fecha.getMonth() + 1), 2) + "" + this.cerosIzquierda('' + fecha.getDate(), 2);
        this.fechaAplicacion = manana.getFullYear() + "" + this.cerosIzquierda('' + (manana.getMonth() + 1), 2) + "" + this.cerosIzquierda('' + manana.getDate(), 2);
        this.fechaExpiracion = exp.getFullYear() + "" + this.cerosIzquierda('' + (exp.getMonth() + 1), 2) + "" + this.cerosIzquierda('' + exp.getDate(), 2)

    }
    getTipoMovimiento() {
        switch (this.estatus) {
            case '01':
                return 'Nueva instrucción de pago';
            case '02':
                return '';
            case '03':
                return 'Instrucción cancelada';
            case '04':
                return 'Instrucción suspendida';
            case '05':
                return 'Instrucción reactivada';
        }
    }
    setCancelacion() {
        this.estatus = '03';
    }
    getReferencia() {
        return this.espaciosDerecha('PROVIDENT' + this.cerosIzquierda('' + this.referencia, 11), 30)
    }
    toJson(){
        return{ 
            nombreBeneficiario: this.nombreBeneficiario,
            sucursal: this.sucursal,
            serial: this.serial,
            direccion: this.direccion,
        }
    }
    toFile(){
        let cadena = "";

        let montoDispersar: number = + (this.monto * 100).toFixed(2);

        cadena += '' + this.cuentaDebito;
        cadena += '' + this.moneda;
        cadena += '' + this.estatus;
        cadena += '' + this.espaciosDerecha(this.nombreBeneficiario.substr(0, 40), 40);
        cadena += '' + this.cerosIzquierda('' + montoDispersar, 15); //MONTO EXPRESADO EN CENTAVOS
        cadena += '' + this.getReferencia();
        cadena += '' + this.fechaAplicacion;
        cadena += '' + this.fechaValor;
        cadena += '' + this.fechaExpiracion;
        cadena += '' + this.usuario;
        cadena += '' + this.sucursal;
        cadena += '' + this.serial;
        cadena += '' + this.folio;
        cadena += '' + this.numeroServicioDAP;
        cadena += '' + this.cuentaBeneficiaria;
        cadena += '' + this.codigoPago;

        return cadena;
    }
    toPDF() {
        return {
            nombre: this.nombreBeneficiario,
            monto: this.monto,
            referencia: this.getReferencia(),
            tipoMovimiento: this.getTipoMovimiento(),
            autorizador: this.autorizador,
            tipoDeVenta: this.tipoDeVenta,
            sucursalName: this.sucursalName,
            fechaAprobacion: this.fechaAprobacion,
            fechaDispersion: this.fechaDispersion,
            fechaCancelacion: this.fechaCancelacion,
            cancelador: this.cancelador,
            esDevolucion:this.esDevolucion
        };
    }

    espaciosDerecha(cadena: string, espacios: number = 40) {
        for (let i = cadena.length; i < espacios; i++) {
            cadena = cadena + ' ';
        }
        return cadena;
    }

    cerosIzquierda(cadena: string, ceros: number = 15) {
        for (let i = cadena.length; i < ceros; i++) {
            cadena = '0' + cadena;
        }
        return cadena;
    }
} 