import { DispersionService } from './dispersion.service';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ArchivoAdjunto } from '../interfaces/adjuntos.interface';
import { ResumenConciliacion } from 'app/models/archivos';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  archivoExcelCorreo: ArchivoAdjunto;

  constructor() { }

  crearExcel(nombre_archivo: string, datos: any) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Resultados");
    for (let x1 of datos) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp);

    }

    //set downloadable file name
    let fname = nombre_archivo || "Resultados";
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //+'-'+new Date().valueOf()+
      fs.saveAs(blob, fname + '.xlsx');
    });
  }


  crearExcelConciliacion(nombre_archivo: string, data: ResumenConciliacion) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Resumen");
    let worksheet2 = workbook.addWorksheet("Información Layout");
    let worksheet3 = workbook.addWorksheet("Información Respuesta");

    //merge cells
    worksheet.mergeCells('A1:E1');
    worksheet.getCell('A1').value = 'Provident México.';
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A2:E2');
    worksheet.getCell('A2').value = 'Conciliación de Órdenes de Pago (E - Disbursement)';
    worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };

    const col = worksheet.getCell('A2').col;

    worksheet.addRow({}).commit();
    worksheet.addRow(['Fecha de conciliación:', data.fechaConciliacion]);
    worksheet.addRow(['Hora de conciliación:', data.hora]);
    worksheet.addRow(['Banco/Corresponsal:', data.corresponsal]);
    worksheet.addRow(['Usuario que generó layout de dispersión:', data.usuarioLayoutDispersion]);
    worksheet.addRow(['Usuario que realizó la conciliación:', data.usuarioConciliacion]);
    worksheet.addRow(['', '']);
    worksheet.addRow(['Resumen de transacciones:', '']);
    worksheet.addRow(['', 'Total de trancciones layout dispersión', data.totalTransaccionesDispersion]);
    worksheet.addRow(['', 'Total de trancciones archivo de respuesta', data.totalTransaccionesRespuesta]);
    let cell = worksheet.addRow(['', 'Datos coinciden (-) / No coinciden (#)', data.concidencias]);
    cell.alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.addRow(['', '']);
    worksheet.addRow(['Detalle de transacciones:', '']);

    let cell2 = worksheet.addRow(['', '', 'Nombre cliente', 'Monto a dispersar', 'Referencia', 'Monto MXN']);
    cell2.font = { bold: true };

    worksheet.addRow(['', 'Total trancciones layout', data.totalTransaccionesDispersion, data.totalTransaccionesDispersion, data.totalTransaccionesDispersion, Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.montoTotalDispersion)]);
    worksheet.addRow(['', 'Monto a dispersar de archivo respuesta', data.totalTransaccionesRespuesta, data.totalTransaccionesRespuesta, data.totalTransaccionesRespuesta, Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.montoTotalRespuesta)]);
    worksheet.addRow(['', 'Diferencias:', data.nombreNoCoincideCount != 0 ? data.nombreNoCoincideCount : '-', data.montoNoCoincideCount != 0 ? data.montoNoCoincideCount : '-', data.noReferenciasCount != 0 ? data.noReferenciasCount : '-', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.montoTotalDispersion - data.montoTotalRespuesta)]);

    // Se centran los numeros y celdas en detalle
    worksheet.getCell('C17').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('C18').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('C19').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('C20').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('D17').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('D18').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('D19').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('E17').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('E18').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('E19').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('F17').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('F18').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('F19').alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.addRow(['', '']);
    worksheet.addRow(['', '']);
    worksheet.addRow(['', '']);

    if (data.coincideTodo) {
      worksheet.addRow(['Transacciones detectadas con diferencias:', 'TODAS LAS TRANSACCIONES SON IGUALES, NO HAY DIFERENCIAS']);
    } else {
      worksheet.addRow(['Transacciones detectadas con diferencias:', '']);
      worksheet.addRow(['', 'Transacciones Layout']);
      let cell3 = worksheet.addRow(['', 'Nombre Cliente', 'Monto a Dispersar', 'Referencia']);
      cell3.font = { bold: true };
      for (let x1 of data.registrosNoConciliadosDispersion) {
        let temp = ['', x1.nombreCliente, Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(x1.montoDispersado), x1.referencia];
        worksheet.addRow(temp);
      }
      worksheet.addRow(['', '']);
      worksheet.addRow(['', 'Transacciones Respuesta']);
      let cell4 = worksheet.addRow(['', 'Nombre Cliente', 'Monto a Dispersar', 'Referencia']);
      cell4.font = { bold: true };
      for (let x1 of data.registrosNoConciliadosRespuesta) {
        let temp = ['', x1.nombreCliente, Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(x1.montoDispersado), x1.referencia];
        worksheet.addRow(temp);
      }

    }

    for (let x1 of data.registrosDispersion) {
      let temp = [x1.nombreCliente, Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(x1.montoDispersado), x1.referencia];
      worksheet2.addRow(temp);
    }

    worksheet2.getColumn('B').alignment = { vertical: 'middle', horizontal: 'right' }

    for (let x1 of data.registrosRespuesta) {
      let temp = [x1.nombreCliente, Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(x1.montoDispersado), x1.referencia];
      worksheet3.addRow(temp);
    }

    worksheet3.getColumn('B').alignment = { vertical: 'middle', horizontal: 'right' }

    //set downloadable file name
    let fname = nombre_archivo || "Resultados";
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //+'-'+new Date().valueOf()+
      fs.saveAs(blob, fname + '.xlsx');
    });
  }

  async crearExcel_Marketplace(nombre_archivo: string, datos: any) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Resultados");
    for (let x1 of datos) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp);

    }

    //set downloadable file name
    let fname = nombre_archivo || "Resultados";
    //add data and file name and download
    const data = await workbook.xlsx.writeBuffer();
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //+'-'+new Date().valueOf()+
    fs.saveAs(blob, fname + '.xlsx');
    this.archivoExcelCorreo = {
      filename: fname + '.xlsx',
      contenido: blob
    }
    return this.archivoExcelCorreo;
  }
}
