import { RegistroDispersion } from "./registroDispersion.class";

export class  Dispersion { 

    public version = '1';
    public formatoArchivo: string = "MXPDLF"; 
    public nivelAutorizacion: string = "F"; 
    public montos: RegistroDispersion[] = [];
    public get totalOperaciones(){
        //El total de operaciones son las dispersiones
        return this.montos.length;
    }; 
    public get montoTotal(){
        //La suma de las dispersiones
        let montoTotal: number = 0;
        for(let i = 0; i < this.montos.length; i++){
            let cantidad: number =  + this.montos[i].monto.toFixed(2);
            if(this.montos[i].esDevolucion){
                montoTotal -= cantidad;
            }else{
                montoTotal += cantidad; 
            }
        }
        let respuesta: number = +montoTotal.toFixed(2);
        return respuesta;
    };  

    constructor() {
    }

    getNombreArchivo(){
        let fecha = new Date();  
        return "OPHSBC" + fecha.getFullYear() + "" + this.cerosIzquierda(''+(fecha.getMonth()+1),2) + "" + this.cerosIzquierda(''+fecha.getDate(),2) + this.cerosIzquierda(this.getVersion(), 21);
    }
    setVersion(version: any){
        this.version = version + '';
    }
    getFecha(){
        let fecha = new Date();  
        return this.cerosIzquierda(''+fecha.getDate(),2)  + "/" + this.cerosIzquierda(''+(fecha.getMonth()+1),2) + "/" +  fecha.getFullYear() 
    }

    getVersion(){
        return this.version;
    }

    addRegistro( registro: RegistroDispersion){
        this.montos.push( registro );
    }

    toFile(){
        //Se presenta en formato de la cabecera.

        let montoTitulo: number = +this.montoTotal.toFixed(2);
        let respuesta = ""  + this.formatoArchivo;
        respuesta += ""    + this.getNombreArchivo(); 
        respuesta += ""    + this.nivelAutorizacion ;
        respuesta += ""    + this.cerosIzquierda(''+( ( montoTitulo  * 100 ).toFixed(0) ) ,15); //MONTO EXPRESADO EN CENTAVOS
        respuesta += ""    + this.cerosIzquierda(''+this.totalOperaciones, 9) + "";
        
        //Se agrega linea a linea
        for(let i = 0; i < this.montos.length; i++){ 
            respuesta += "\n"+this.montos[i].toFile() ;
        }
        console.log("Archivo generado");
        console.log(respuesta);
        return  respuesta;
    };
    
    toMarketplace(){
        //primero validamos si el monto total es igual a 0
        if(+this.montoTotal===0){
            return {
                esCero:true,
                respuesta:''
            };
        }
        //Se presenta en formato de la cabecera.

        let respuesta = "";
        
        respuesta =  "1";                                           //Transacciones
        respuesta += ",4067839068";                                 //Cuenta ordenante
        respuesta += ",4011108826";                                 //Cuenta beneficiaria
        respuesta += ","+this.montoTotal.toFixed(2)                 //Sumatoria registros
        respuesta += ",1"                                           //Moneda;
        respuesta += ",PROVIDENT"                                   //Referencia;
        respuesta += ",MCI Networks S.A.P.I. de C.V."               //Nombre del beneficiario
        respuesta += ",0"                                           //Comprobante fiscal
        respuesta += ","                                            //RFC del beneficiario
        respuesta += ","                                            //IVA
        respuesta += ","                                            //Correo electronico
        respuesta += "\n"                                           //Salto de línea

        //Segunda linea
        respuesta += "1"                                            //Numero de bloque
        respuesta += ",1"                                           //Transacciones
        respuesta += ","+ this.espaciosDerecha('PAGO PROVIDENT',40);//concepto
        respuesta += ","                                            //Filler 1
        respuesta += ","                                            //Filler 2
        respuesta += ","                                            //Filler 3
        respuesta += ","                                            //Filler 4
        respuesta += ","                                            //Filler 5
        respuesta += ","                                            //Filler 6
        respuesta += ","                                            //Filler 7
        respuesta += ","                                            //Filler 8

        
 
        console.log("Archivo generado", respuesta);
        return  {
            esCero:false,
            respuesta
        }
    };
    toPDF(){
        let arregloMontos = [];

        for(let i = 0; i < this.montos.length ; i++){
            arregloMontos.push( this.montos[i].toPDF() )
        }

        return { 

            cabecera: {
                nombreArchivo: this.getNombreArchivo(),
                montoTotal: this.montoTotal,
                fecha: this.getFecha(),
                totalOperaciones: this.totalOperaciones
            },
            cuerpo:arregloMontos
        };
    }

    cerosIzquierda(cadena: string, ceros: number = 30){
        for(let i = cadena.length; i < ceros; i++){
            cadena = '0' + cadena;
        }
        return cadena;
    }
    
    espaciosDerecha(cadena: string, espacios: number = 40){
        for(let i = cadena.length; i < espacios; i++){
            cadena =  cadena + ' ';
        }
        return cadena;
    }
    
}