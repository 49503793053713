import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Router } from '@angular/router'; 
import { NgxSpinnerService } from "ngx-spinner";
import { NotificacionService } from './notificacion.service';
import { Cache } from 'aws-amplify'; 
import jwt_decode from "jwt-decode";

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'; 
const base_url = environment.lambda_url;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public emailRegistro = "";
  private loginError = false;
  private loginErrorMsg = '';
  private pasoLogin = 'adfs'; //inicio actualiza-password

  constructor(
    private notificacion: NotificacionService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
  ) { }
  
  public get token() {
    let token = localStorage.getItem('token');
    if(token == undefined){
      return '';
    }else{
      return token
    }
  }
  public get  headers () {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }
  public getDatosUsuario(): any{
    let token = this.token;
    let decoded = jwt_decode(token);
    return decoded;
  }

  guardarToken(token: string){
    localStorage.setItem('token', token ); 
  } 

  setPaso(paso: string){
    this.pasoLogin = paso;
  }
  getPaso(){
    return this.pasoLogin;
  }
  irInicio(){
    this.router.navigateByUrl('/paso-1');
  }
  irRegistro(){
    this.setPaso('registro');
  }
  irLogin(){
    this.setPaso('inicio'); 
  }
  irActualizaPassword(){
    this.setPaso('actualiza-password'); 
  }
  irSFA(){ //Validacion de codigo
    this.setPaso('sfa'); 
  }

  getErrorLogin(){
    return this.loginError;
  }
  errorLoginMensaje(){
    return this.loginErrorMsg;
  }
  restaurarErrores(){
    this.loginError = false;
    this.loginErrorMsg = '';
    this.emailRegistro = '';
  }
  errorLogin(mensaje: string){
    this.loginError = true;
    this.loginErrorMsg = mensaje;
  }

  async getAutorizacionTesoreria(){
    return await new Promise( (resolve, reject) => {
      this.http.get(`${ base_url }/api/usuarios-teso/autorizacion`,  this.headers ).subscribe( data => {
        console.log(data);
        resolve(true);
      }, async err => {
        await Auth.signOut();
        this.router.navigate(['/']); 
        console.log(err);
        reject( false );
      });
    }).then(r => {
      return true
    }).catch( r => {
      return false;
    });
  }
  getEstatusLogeo(){
    return Auth.currentAuthenticatedUser().then((user) => { 
      console.log(user);
      this.guardarToken(user.signInUserSession.accessToken.jwtToken); 
      return true;
    }).catch((error)=>{ 
      this.router.navigate(['/']);
      return false;
    })  
  }
  async cerrarSesion() {
    try {
      await Auth.signOut();
      this.router.navigate(['/']); 
    } catch (error) {
        console.log('error signing out: ', error);
    } 
  }

  actualizarDatos(){
    this.spinner.show();
    return Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log(user);
      Auth.updateUserAttributes(user,{
        name: ''
      })
    }).then( ok => {
      this.spinner.hide();
      return ok;
    }).catch(r => {
      this.spinner.hide();
      return r;
    }); 
  } 

  actualizarPassword( oldPassword: string, newPassword: string){
    this.spinner.show();
    return Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log(user);
      return Auth.changePassword(user,oldPassword,newPassword); 
    }).then( ok => {
      this.spinner.hide();
      return ok;
    }).catch(r => {
      this.spinner.hide();
      return r;
    }); 
  }
  async iniciarSesionADFS(){  
    console.log("Iniciar sesion ADFS");
    Auth.federatedSignIn().then(cred => {
        // If success, you will get the AWS credentials
        console.log(cred);
 
        // Run this after the sign-in
        const federatedInfo = Cache.getItem('federatedInfo');
        const { token } = federatedInfo;

        console.log('token',token);

        return Auth.currentAuthenticatedUser();
    }).then(user => {
        // If success, the user object you passed in Auth.federatedSignIn
        console.log(user);
    }).catch(e => {
        console.log('federatedSignIn catch');
        console.log(e)
    });

  }
  async iniciarSesion(email: string, password: string){
    this.restaurarErrores(); 
    this.spinner.show();
    try {  
      var user = await Auth.signIn(email, password); 
      this.spinner.hide();
      console.log('Authentication performed for user=' + email + 'password=' + password + ' login result==');
      console.log(user);

      if(!user.challengeName){ 
        var tokens = user.signInUserSession; 
        if (tokens != null) {
          console.log('User authenticated');
          this.irInicio(); 
        }
      }else{
        switch(user.challengeName){
          case 'NEW_PASSWORD_REQUIRED':
            this.emailRegistro = email;
            this.irActualizaPassword();
            break;
          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
            this.errorLogin(user.challengeName);
            this.irSFA();
            break;
          case 'SMS_MFA':
          case 'SMS_MFA_CODE':
          default:
            console.log(user.challengeName);
            this.errorLogin(user.challengeName);
            break;
        }  
      }
    } catch (error) {
      this.spinner.hide();
      console.log(error); 
      switch(error.code){
        case 'InvalidParameterException':
          this.errorLogin('Parámetro de configuración inválido');
          break;
        case 'UserNotFoundException':
          this.errorLogin('No existe el usuario');
          break;
        case 'NotAuthorizedException':
          this.errorLogin('Usuario o contraseña inválidos');
          break;
        case 'UserNotConfirmedException':
          this.errorLogin('El usuario no está confirmado');
          break;
        default:
          this.errorLogin('Falló la autenticación'); 
          break;
      }  
      
    }
  }

  async registrar(email: string, password: string, nombre: string, apellido: string){
    this.spinner.show();
    try {
      const user = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          given_name: nombre,
          family_name: apellido
        }
      });
      console.log({ user });
      this.spinner.hide();
      this.irLogin();  
      this.notificacion.showNotification('top','center','success','Registrado correctamente');
    } catch (error) { 
      this.spinner.hide();
      this.notificacion.showNotification('top','center','danger','Error al registrar');
      console.log('ERROR',error);
      switch(error.code){
        case "InvalidPasswordException":
          if(error.message.includes("Password not long enough")){
            this.errorLogin('El password no cumple con la política. El password no es lo suficientemente largo.');
            break;
          }
          if(error.message.includes("Password must have uppercase characters")){
            this.errorLogin('El password no cumple con la política. El password debe tener mayúsculas.');
            break;
          }
          if(error.message.includes("Password must have lowercase characters")){
            this.errorLogin('El password no cumple con la política. El password debe tener minúsculas.');
            break;
          }
          if(error.message.includes("Password must have symbol characters")){
            this.errorLogin('El password no cumple con la política. El password debe tener caracteres especiales.');
            break;
          }  

      }
      console.log('error signing up:', error);
    }
  }

  generarJWT(nombre,correo){
    return new Promise( ( resolve, reject ) => {

        const payload = {
          nombre,
          correo
        };
        /*
        jwt.sign( payload, jwt_token, {
            expiresIn: '12h'
        }, ( err, token ) => {
    
            if ( err ) {
                console.log(err);
                reject('No se pudo generar el JWT');
            } else {
                resolve( token );
            }
    
        }); /*/

    });
  }
}
