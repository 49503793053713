import { DispersionService } from './dispersion.service';
import { Injectable } from '@angular/core';
import { Dispersion } from 'app/interfaces/dispersion.class';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Telecom } from '../interfaces/telecom.class';
import { Marketplace } from '../interfaces/marketplace.class';
import { ArchivoAdjunto } from '../interfaces/adjuntos.interface';
import { retry } from 'rxjs-compat/operator/retry';
import { ResumenConciliacion } from 'app/models/archivos';
import { DispersionBbva } from 'app/interfaces/dispersion.bbva.class';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  generatePDF(nombreArchivo: string, registros: Dispersion, responsable: string, tipo: string = "dispersion") {
    console.log('Nombre archivo', nombreArchivo);
    console.log('registros', registros);

    let fechaGeneracion = new Date();

    let dispersiones = registros.toPDF();

    let formatoReferencias = [];
    let columnasReferencias: string[];
    if (tipo == "cancelacion") {
      columnasReferencias = ['NO.', 'TIPO DE VENTA', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE CANCELÓ', 'SUCURSAL', 'FECHA/HORA CANCELACIÓN', 'FECHA/HORA DISPERSIÓN'];
      for (let i = 0; i < dispersiones.cuerpo.length; i++) {
        formatoReferencias.push({
          'NO.': (i + 1) + '',
          'TIPO DE VENTA': '' + dispersiones.cuerpo[i].tipoDeVenta,
          'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
          'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
          'TIPO': '' + dispersiones.cuerpo[i].tipoMovimiento,
          'PERSONAL ADMIN QUE CANCELÓ': '' + dispersiones.cuerpo[i].cancelador,
          'SUCURSAL': '' + dispersiones.cuerpo[i].sucursalName,
          'FECHA/HORA CANCELACIÓN': '' + dispersiones.cuerpo[i].fechaCancelacion,
          'FECHA/HORA DISPERSIÓN': '' + dispersiones.cuerpo[i].fechaDispersion,
        });
      }
    } else if (tipo == "dispersion") {
      columnasReferencias = ['NO.', 'TIPO DE VENTA', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE APROBÓ', 'SUCURSAL', 'FECHA Y HORA'];
      for (let i = 0; i < dispersiones.cuerpo.length; i++) {
        formatoReferencias.push({
          'NO.': (i + 1) + '',
          'TIPO DE VENTA': '' + dispersiones.cuerpo[i].tipoDeVenta,
          'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
          'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
          'TIPO': '' + dispersiones.cuerpo[i].tipoMovimiento,
          'PERSONAL ADMIN QUE APROBÓ': '' + dispersiones.cuerpo[i].autorizador,
          'SUCURSAL': '' + dispersiones.cuerpo[i].sucursalName,
          'FECHA Y HORA': '' + dispersiones.cuerpo[i].fechaAprobacion
        });
      }
    }


    let docDefinition = {

      pageOrientation: 'landscape',
      content: [
        { text: 'NOMBRE ARCHIVO: ' + dispersiones.cabecera.nombreArchivo, style: 'header' },
        {
          style: 'tableExample',
          table: {
            widths: [90, 200, 120, 85],
            body: [
              ['FECHA', 'PERSONA RESPONSABLE', 'MONTO', 'OPERACIONES'],
              [dispersiones.cabecera.fecha, '' + responsable, '$' + (dispersiones.cabecera.montoTotal.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","), dispersiones.cabecera.totalOperaciones]
            ]
          }
        },
        { text: ' ', style: 'header' },
        {
          style: 'tableExample',
          table: {
            body: this.buildTableBody(formatoReferencias, columnasReferencias)
          }
        },
      ]
    };

    pdfMake.createPdf(docDefinition).download(dispersiones.cabecera.nombreArchivo + '.pdf') //.download();  //open();  
  }

  generatePDFBbva(nombreArchivo: string, registros: DispersionBbva, responsable: string, tipo: string = "dispersion") {
    console.log('Nombre archivo', nombreArchivo);
    console.log('registros', registros);

    let fechaGeneracion = new Date();

    let dispersiones = registros.toPDF();

    let formatoReferencias = [];
    let columnasReferencias: string[];

    columnasReferencias = ['NO.', 'TIPO DE VENTA', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE APROBÓ', 'SUCURSAL', 'FECHA Y HORA'];
    for (let i = 0; i < dispersiones.cuerpo.length; i++) {
      formatoReferencias.push({
        'NO.': (i + 1) + '',
        'TIPO DE VENTA': '' + dispersiones.cuerpo[i].tipoDeVenta,
        'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
        'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
        'TIPO': 'Nueva instrucción de pago.',
        'PERSONAL ADMIN QUE APROBÓ': '' + dispersiones.cuerpo[i].autorizador,
        'SUCURSAL': '' + dispersiones.cuerpo[i].sucursalName,
        'FECHA Y HORA': '' + dispersiones.cuerpo[i].fechaAprobacion
      });
    }

    let docDefinition = {

      pageOrientation: 'landscape',
      content: [
        { text: 'NOMBRE ARCHIVO: ' + dispersiones.cabecera.nombreArchivo, style: 'header' },
        {
          style: 'tableExample',
          table: {
            widths: [90, 200, 120, 85],
            body: [
              ['FECHA', 'PERSONA RESPONSABLE', 'MONTO', 'OPERACIONES'],
              [dispersiones.cabecera.fecha, '' + responsable, '$' + (dispersiones.cabecera.montoTotal.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","), dispersiones.cabecera.totalOperaciones]
            ]
          }
        },
        { text: ' ', style: 'header' },
        {
          style: 'tableExample',
          table: {
            body: this.buildTableBody(formatoReferencias, columnasReferencias)
          }
        },
      ]
    };

    pdfMake.createPdf(docDefinition).download(dispersiones.cabecera.nombreArchivo + '.pdf') //.download();  //open();  
  }

  generatePDF_Telecom(nombreArchivo: string, registros: Telecom, responsable: string) {
    console.log('Nombre archivo', nombreArchivo);
    console.log('registros', registros);

    let fechaGeneracion = new Date();

    let dispersiones = registros.toPDF();

    let formatoReferencias = [];
    let columnasReferencias = ['NO.', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE APROBÓ'];
    for (let i = 0; i < dispersiones.cuerpo.length; i++) {
      formatoReferencias.push({
        'NO.': (i + 1) + '',
        'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
        'MONTO A DISPERSAR': '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
        'TIPO': '' + dispersiones.cuerpo[i].tipoMovimiento,
        'PERSONAL ADMIN QUE APROBÓ': '' + dispersiones.cuerpo[i].autorizador
      });
    }

    let docDefinition = {

      pageOrientation: 'landscape',
      content: [
        { text: 'NOMBRE ARCHIVO: ' + dispersiones.cabecera.nombreArchivo, style: 'header' },
        {
          style: 'tableExample',
          table: {
            widths: [90, 200, 120, 85],
            body: [
              ['FECHA', 'PERSONA RESPONSABLE', 'MONTO', 'OPERACIONES'],
              [dispersiones.cabecera.fecha, '' + responsable, '$' + (dispersiones.cabecera.montoTotal.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","), dispersiones.cabecera.totalOperaciones]
            ]
          }
        },
        { text: ' ', style: 'header' },
        {
          style: 'tableExample',
          table: {
            body: this.buildTableBody(formatoReferencias, columnasReferencias)
          }
        },
      ]
    };

    pdfMake.createPdf(docDefinition).download(dispersiones.cabecera.nombreArchivo + '.pdf') //.download();  //open();  
  }

  generatePDF_Marketplace(nombreArchivo: string, registros: Marketplace, responsable: string): Promise<ArchivoAdjunto> {
    console.log('Nombre archivo', nombreArchivo);
    console.log('registros', registros);

    let fechaGeneracion = new Date();

    let dispersiones = registros.toPDF();

    let formatoReferencias = [];
    let columnasReferencias = ['NO.', 'FECHA', 'NOMBRE DEL CLIENTE', 'MONTO A DISPERSAR', 'MONTO A CANCELAR', "CODIGO TRANSACCION", 'REFERENCIA', 'TIPO', 'PERSONAL ADMIN QUE APROBÓ'];
    for (let i = 0; i < dispersiones.cuerpo.length; i++) {
      const esDevolucion = dispersiones.cuerpo[i].esDevolucion;
      formatoReferencias.push({
        'NO.': (i + 1) + '',
        'FECHA': dispersiones.cabecera.fecha,
        'NOMBRE DEL CLIENTE': '' + dispersiones.cuerpo[i].nombre,
        'MONTO A DISPERSAR': esDevolucion ? '' : '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        'MONTO A CANCELAR': esDevolucion ? '$' + (dispersiones.cuerpo[i].monto.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
        'CODIGO TRANSACCION': esDevolucion ? '1002' : '1001',
        'REFERENCIA': '' + dispersiones.cuerpo[i].referencia,
        'TIPO': esDevolucion ? 'Nueva instrucción de devolución' : 'Nueva instrucción de pago',
        'PERSONAL ADMIN QUE APROBÓ': '' + dispersiones.cuerpo[i].autorizador
      });
    }

    let docDefinition = {

      pageOrientation: 'landscape',
      content: [
        { text: 'NOMBRE ARCHIVO: ' + dispersiones.cabecera.nombreArchivo, style: 'header' },
        {
          style: 'tableExample',
          table: {
            widths: [90, 200, 120, 85],
            body: [
              ['FECHA', 'PERSONA RESPONSABLE', 'MONTO', 'OPERACIONES'],
              [dispersiones.cabecera.fecha, responsable, '$' + (dispersiones.cabecera.montoTotal.toFixed(2) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ","), dispersiones.cabecera.totalOperaciones]
            ]
          }
        },
        { text: ' ', style: 'header' },
        {
          style: 'tableExample',
          table: {
            body: this.buildTableBody(formatoReferencias, columnasReferencias)
          }
        },
      ]
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(dispersiones.cabecera.nombreArchivo + '.pdf') //.download();


    return new Promise((resolve, reject) => {
      try {
        pdfDocGenerator.getBlob(async (blob: any) => {
          resolve({
            filename: dispersiones.cabecera.nombreArchivo + '.pdf',
            contenido: blob
          });
        });
      } catch (err) {
        console.log("Error en promesa PDF");
        console.log(err);
        reject(err);
      }
    })
  }
  buildTableBody(data, columns) {
    var body = [];

    body.push(columns);

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column) {
        dataRow.push(row[column]);
      })

      body.push(dataRow);
    });

    return body;
  }

  generatePDFConciliacion(nombreArchivo: string, data: ResumenConciliacion) {

    let detallesDiferencias: any = {
      stack: [
        {
          text: 'TODAS LAS TRANSACCIONES SON IGUALES, NO HAY DIFERENCIAS'
        },
      ],
      margin: [190, 10, 0, 0]
    };

    if (!data.coincideTodo) {
      const filasDisp: any[][] = [
        [
          { text: 'Nombre Cliente', style: { bold: true } },
          { text: 'Monto a Dispersar', style: { bold: true } },
          { text: 'Referencia', style: { bold: true } }
        ],
      ];
      const filasResp: any[][] = [
        [
          { text: 'Nombre Cliente', style: { bold: true } },
          { text: 'Monto a Dispersar', style: { bold: true } },
          { text: 'Referencia', style: { bold: true } }
        ],
      ];
      data.registrosNoConciliadosDispersion.forEach(r => {
        const fila = [r.nombreCliente, { text: this.numberWithCommas(r.montoDispersado), style: { alignment: 'right' } }, r.referencia];
        filasDisp.push(fila);
      })
      data.registrosNoConciliadosRespuesta.forEach(r => {
        const fila = [r.nombreCliente, { text: this.numberWithCommas(r.montoDispersado), style: { alignment: 'right' } }, r.referencia];
        filasResp.push(fila);
      })
      detallesDiferencias = {
        stack: [
          'Registros Base de datos',
          {
            style: 'tableExample',
            table: {
              body: filasDisp
            }
          },
          { text: ' ', style: 'header' },
          'Transacciones Response',
          {
            style: 'tableExample',
            table: {
              body: filasResp
            }
          }
        ],
        margin: [190, 0, 0, 0]
      }
    }
    const filasDisp2: any[][] = [
      [
        { text: 'Nombre Cliente', style: { bold: true } },
        { text: 'Monto a Dispersar', style: { bold: true } },
        { text: 'Referencia', style: { bold: true } }
      ],
    ];
    data.registrosDispersion.forEach(r => {
      const fila = [r.nombreCliente, { text: this.numberWithCommas(r.montoDispersado), style: { alignment: 'right' } }, r.referencia];
      filasDisp2.push(fila);
    })
    const filasResp2: any[][] = [
      [
        { text: 'Nombre Cliente', style: { bold: true } },
        { text: 'Monto a Dispersar', style: { bold: true } },
        { text: 'Referencia', style: { bold: true } }
      ],
    ];
    data.registrosRespuesta.forEach(r => {
      const fila = [r.nombreCliente, { text: this.numberWithCommas(r.montoDispersado), style: { alignment: 'right' } }, r.referencia];
      filasResp2.push(fila);
    })

    let corresponsal = '';
    if(data.corresponsal === 'BBVA') {
      corresponsal = data.corresponsal;
    } else {
      corresponsal = 'E - Disbursement';
    }

    const docDefinition = {

      pageOrientation: 'landscape',
      pageMargins: [40, 30, 40, 30],
      content: [
        { text: 'Provident México. \nConciliación de Órdenes de Pago ('+ corresponsal + ')\n', style: 'header' },
        { text: ' ', style: 'header' },
        {
          table: {
            widths: ['auto', 'auto'],
            body: [
              ["Fecha de conciliación:", data.fechaConciliacion.toISOString().split("T")[0]],
              ["Hora de conciliación:", data.hora],
              ["Banco/Corresponsal:", data.corresponsal],
              ["Usuario que generó layout de dispersión:", data.usuarioLayoutDispersion],
              ["Usuario que realizó la conciliación:", data.usuarioConciliacion]
            ]
          }
        },
        { text: ' ', style: 'header' },
        'Resumen de transacciones:',
        {
          stack: [
            {
              table: {
                widths: ['auto', 25],
                body: [
                  ["Total Registros Base de datos", { text: data.totalTransaccionesDispersion, style: { alignment: 'center' } }],
                  ["Total de transacciones archivo de respuesta (Response)", { text: data.totalTransaccionesRespuesta, style: { alignment: 'center' } }],
                  [{ text: "Datos coinciden (-) / No coinciden (#):", style: { alignment: 'right' } }, { text: data.noConinciden != 0 ? data.noConinciden : '-', style: { alignment: 'center' } }]
                ]
              }
            },
          ],
          margin: [160, 0, 0, 0]
        },
        { text: ' ', style: 'header' },
        'Detalle de transacciones:',
        {
          stack: [
            {
              style: 'tableExample',
              table: {
                body: [
                  [
                    { text: '', border: [false, false, true, true] },
                    { text: 'Nombre Cliente', style: { bold: true } },
                    { text: 'Monto a dispersar', style: { bold: true } },
                    { text: 'Referencia', style: { bold: true } },
                    { text: 'Monto MXN', style: { bold: true } }
                  ],
                  [
                    'Total Registros Base de datos',
                    { text: data.totalTransaccionesDispersion, style: { alignment: 'center' } },
                    { text: data.totalTransaccionesDispersion, style: { alignment: 'center' } },
                    { text: data.totalTransaccionesDispersion, style: { alignment: 'center' } },
                    { text: this.numberWithCommas(data.montoTotalDispersion), style: { alignment: 'center' } }
                  ],
                  [
                    'Monto a Dispersar de Archivo Response',
                    { text: data.totalTransaccionesRespuesta, style: { alignment: 'center' } },
                    { text: data.totalTransaccionesRespuesta, style: { alignment: 'center' } },
                    { text: data.totalTransaccionesRespuesta, style: { alignment: 'center' } },
                    { text: this.numberWithCommas(data.montoTotalRespuesta), style: { alignment: 'center' } }
                  ],
                  [
                    { text: 'Diferencias:', style: { alignment: 'right' } },
                    { text: data.nombreNoCoincideCount != 0 ? data.nombreNoCoincideCount : '-', style: { alignment: 'center' } },
                    { text: data.montoNoCoincideCount != 0 ? data.montoNoCoincideCount : '-', style: { alignment: 'center' } },
                    { text: data.noReferenciasCount != 0 ? data.noReferenciasCount : '-', style: { alignment: 'center' } },
                    { text: (data.montoTotalDispersion - data.montoTotalRespuesta) != 0 ? this.numberWithCommas(data.montoTotalDispersion - data.montoTotalRespuesta) : '-', style: { alignment: 'center' } }
                  ]
                ]
              },
            },
          ],
          margin: [160, 0, 0, 0]
        },
        { text: ' ', style: 'header' },
        'Transacciones detectadas con diferencias:',
        detallesDiferencias,
        { text: 'Información  Base de datos', pageBreak: 'before' },
        { text: ' ', style: 'header' },
        {
          style: 'tableExample',
          table: {
            body: filasDisp2
          }
        },
        { text: 'Información Response', pageBreak: 'before' },
        { text: ' ', style: 'header' },
        {
          style: 'tableExample',
          table: {
            body: filasResp2
          }
        }
      ]
    };

    pdfMake.createPdf(docDefinition).download(nombreArchivo + '.pdf') //.download();  //open();  
  }

  numberWithCommas(x) {
    if (x == null) {
      return '0.00'
    }
    else {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let number = parts.join('.')
      if (number.includes('.')) { return number; } else { return (number + '.00') }
    }
  }

}
